import axios from 'axios';

export default {
  namespaced: true,

  state: {
    success: false,
    successConsult: false,
    pending: false,
  },
  getters: {
    success(state) {
      return state.success;
    },
    successConsult(state) {
      return state.successConsult;
    },
    pending(state) {
      return state.pending;
    },
  },
  mutations: {
    setSuccess(state) {
      state.success = true;
    },
    setSuccessConsult(state) {
      state.successConsult = true;
    },
    resetSuccess(state) {
      state.success = false;
    },
    setPending(state) {
      state.pending = true;
    },
    resetPending(state) {
      state.pending = false;
    },
  },
  actions: {
    async SEND_USER_DATA(store, userData) {
      const from = userData.from.$options.name;

      store.commit('setPending');

      await axios
        .get('https://api.abs-autoservice.ru/send/', {
          params: {
            name: userData.name,
            phone: userData.phone,
            formname: userData.formname,
            model: userData.model,
            comment: userData.comment,
          },
        })
        .then((response) => {
          if (response.data === 'ok') {
            store.commit('resetPending');
          }
        });

        switch (from) {
          case 'vHeader':
            ym(93722392, 'reachGoal', 'form_ callback_header');
            return true;
          case 'VServiceSection':
            ym(93722392, 'reachGoal', 'form_ top');
            return true;
          case 'VCardProfitably':
            ym(93722392, 'reachGoal', 'form_ promo');
            return true;
          case 'VQuest':
            ym(93722392, 'reachGoal', 'form_ kviz');
            return true;
          case 'VPopularServices':
            ym(93722392, 'reachGoal', 'form_ service');
            return true;
          case 'VConsultSection':
            ym(93722392, 'reachGoal', 'form_ consultation');
            return true;
          case 'VGetCard':
            ym(93722392, 'reachGoal', 'form_ card');
            return true;
        }
    },
    SET_SUCCESS(store) {
      store.commit('setSuccess');
    },
    RESET_SUCCESS(store) {
      store.commit('resetSuccess');
    },
    SET_SUCCESS_CONSULT(store) {
      store.commit('setSuccessConsult');
    },
  },
};
