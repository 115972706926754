export default {
  mounted(el, binding) {
    window.addEventListener('load', () => {
      const observer = new IntersectionObserver(
        (entries) => {
          const entry = entries[0];

          if (entry.isIntersecting && entry.intersectionRatio) {
            setTimeout(() => {
              const targetSrcAll = entry.target.querySelectorAll('[data-src]');
              const targetSrcsetAll = entry.target.querySelectorAll('[data-srcset]');

              if (Boolean(targetSrcsetAll.length)) {
                Array.from(targetSrcsetAll).forEach((targetSrcset) => {
                  // console.log(targetSrcset);
                  targetSrcset.srcset = targetSrcset.dataset.srcset;
                });
              } else if (Boolean(targetSrcAll.length)) {
                Array.from(targetSrcAll).forEach((targetSrc) => {
                  // console.log(targetSrc);
                  targetSrc.src = targetSrc.dataset.src;
                });
              } else {
                if (binding.value == 'VProfitablySection') {
                  binding.instance.VProfitablySectionVisible = true;
                  // console.log(binding.value);
                }
                if (binding.value == 'VFullscreenImage') {
                  binding.instance.VFullscreenImageVisible = true;
                  // console.log(binding.value);
                }
                if (binding.value == 'VCostSection') {
                  binding.instance.VCostSectionVisible = true;
                  // console.log(binding.value);
                }
                if (binding.value == 'VPopularServices') {
                  binding.instance.VPopularServicesVisible = true;
                  // console.log(binding.value);
                }
                if (binding.value == 'VReviewsSection') {
                  binding.instance.VReviewsSectionVisible = true;
                  // console.log(binding.value);
                }
                if (binding.value == 'VFullscreenImage2') {
                  binding.instance.VFullscreenImage2Visible = true;
                  // console.log(binding.value);
                }
                if (binding.value == 'VConsultSection') {
                  binding.instance.VConsultSectionVisible = true;
                  // console.log(binding.value);
                }
                if (binding.value == 'VModalWindowUIFC') {
                  // binding.instance.VConsultSectionVisible = true;
                  // console.log(binding.value);
                }
              }
            }, 200);

            observer.unobserve(el);
          }
        },
        {
          rootMargin: '0px 0px 100px 0px',
        }
      );
      observer.observe(el);
    });
  },
};

// import lazyLoadDirective from '@/directives/lazy-load';

// directives: {
// 	lazyLoad: lazyLoadDirective,
// },

// v-lazy-load

// export default {
//   mounted(el, binding) {
// console.log(el);

//     window.addEventListener('load', () => {
//       const observer = new IntersectionObserver(
//         (entries) => {
//           const entry = entries[0];

//           if (entry.isIntersecting && entry.intersectionRatio) {
//             switch (binding.value) {
//               case 'VProfitablySection':
//                 binding.instance.VProfitablySectionVisible = true;

//                 // window.addEventListener('load', () => {
//                 //   const targetSrcAll = entry.target.querySelectorAll('[data-src]');
// console.log(targetSrcAll);
//                 //   if (Boolean(targetSrcAll.length)) {
//                 //     Array.from(targetSrcAll).forEach((targetSrc) => {
//                 //       targetSrc.src = targetSrc.dataset.src;
//                 //     });
//                 //   }
//                 // });
//                 break;
//               case 'VFullscreenImage':
//                 binding.instance.VFullscreenImageVisible = true;
// console.log(binding.value);
//                 break;
//               case 'VCostSection':
//                 binding.instance.VCostSectionVisible = true;
// console.log(binding.value);
//                 break;

//               default:
//                 break;
//             }

//             observer.unobserve(el);
//           }
//         },
//         {
//           rootMargin: '0px 0px 100px 0px',
//         }
//       );
//       observer.observe(el);
//     });
//   },
// };
