<template>
  <div class="question">
    <h6 class="question__title">{{ opts.title }}</h6>
    <div class="question__inputs">
      <div class="question__input-name">
        <VInput
          @onInput="$emit('onInput', { value: $event, inputName: 'inputName' })"
          :opts="opts.inputOpts.inputName"
        />
      </div>
      <div class="question__input-phone">
        <VInput
          @onInput="$emit('onInput', { value: $event, inputName: 'inputTel' })"
          :opts="opts.inputOpts.inputTel"
        />
      </div>
    </div>
    <button class="question__privacy-policy">
      Нажимая кнопку “Завершить” вы соглашаетесь с нашей
      <span
        class="question__privacy-policy-link"
        @click="onShowDialogPrivacy"
        >Политикой конфиденциальности
      </span>
    </button>
  </div>
</template>

<script>
  import VInput from '../UI/VInput.vue';

  export default {
    name: 'qu3',
    components: { VInput },
    props: {
      opts: {
        type: Object,
        required: true,
      },
    },
    methods: {
      onShowDialogPrivacy() {
        this.$emit('onShowDialogPrivacy');
      },
    },
  };
</script>

<style lang="scss">
  @import '@/assets/scss/mixins.scss';
  @import '@/assets/scss/smart-grid.scss';

  .question {
    &__inputs {
      display: flex;
      max-width: 512px;
      margin-bottom: 8px;

      @include md-block() {
        flex-direction: column;
      }
    }

    &__input-name {
      margin-right: 20px;

      @include md-block() {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    &__input-phone {
    }

    &__privacy-policy {
      text-align: left;
      cursor: auto;
      background: transparent;
      border: none;
      margin-bottom: 28px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 133%;
      color: #999999;
    }
    &__privacy-policy-link {
      cursor: pointer;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
</style>
