<template>
	<nav class="nav">
		<ul class="nav__list">
			<li class="nav__item"
					v-for="menuItem in menuItems"
					:key="menuItem.slug">
				<a class="nav__link"
					 :href="`#${menuItem.slug}`"
					 @click.prevent="$emit('onGoToAnchor', menuItem.slug)">
					{{ menuItem.name }}
				</a>
			</li>
		</ul>
	</nav>
</template>

<script>
export default {
	props: {
		menuItems: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {}
	},
	computed: {
	},
}
</script>

<style lang="scss">
.logo {}
</style>
