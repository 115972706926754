<!-- <template>
  <div
    class="map"
    id="map"
  ></div>
</template> -->
<!-- to connect to (html => head)  -->
<!-- <script src="https://api-maps.yandex.ru/2.1/?apikey=657c13da-cbea-44f0-8362-2a2beb55e357&lang=ru_RU"></script>  -->

<!-- to will know coords -->
<!-- https://yandex.ru/map-constructor/location-tool/?from=club -->
<!-- <script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'Map',
    computed: {
      ...mapGetters('contacts', {
        contactsBlock: 'block',
      }),
    },

    methods: {
      ...mapActions('contacts', ['GET_CONTACTS_BLOCK']),

      yandexMap(latitude, longitude) {
        const center = [latitude, longitude];
        const zoom = 17;

        if (ymaps) {
          ymaps.ready(init);

          function init() {
            const map = new ymaps.Map('map', {
              center: center, // ваши данные
              zoom: zoom,
            });

            const placeMark = new ymaps.Placemark( // конфигурируем метку
              center,
              {},
              {
                iconLayout: 'default#image',
                iconImageHref: 'https://cdn-icons-png.flaticon.com/512/2776/2776067.png',
                iconImageSize: [40, 40],
                iconImageOffset: [-12.5, -60],
              }
            );

            // map.container.getElement().style.filter = 'grayscale(1)';// вслючить черно-белую карту

            map.controls.remove('geolocationControl'); // удаляем геолокацию
            map.controls.remove('searchControl'); // удаляем поиск
            map.controls.remove('trafficControl'); // удаляем контроль трафика
            map.controls.remove('typeSelector'); // удаляем тип
            map.controls.remove('fullscreenControl'); // удаляем кнопку перехода в полноэкранный режим
            map.controls.remove('zoomControl'); // удаляем контрол зуммирования
            map.controls.remove('rulerControl'); // удаляем контрол правил
            map.behaviors.disable(['scrollZoom']); // отключаем скролл карты (опционально)

            map.geoObjects.add(placeMark); // отобразить метку
          }
        }
      },
    },
    async mounted() {
      await this.GET_CONTACTS_BLOCK();
      this.yandexMap(this.contactsBlock.latitude, this.contactsBlock.longitude);
    },
  };
</script> -->
<!-- <style lang="scss">
  .map {
    width: 100%;
    height: 100%;

    .ymaps-2-1-79-map-copyrights-promo,
    .ymaps-2-1-79-copyright,
    .ymaps-2-1-79-copyright_logo_no {
      display: none;
    }
  }
</style> -->
<template>
  <div
    class="map"
    id="map"
  >
    <iframe
      data-src="https://yandex.ru/map-widget/v1/?um=constructor%3Abf07745ef68e3a312fd40eec7814dc636979444f524d6b267d3c90c4c6cd9b01&source=constructor"
      width="792"
      height="337"
      frameborder="0"
    ></iframe>
  </div>
</template>
<!-- to connect to (html => head) -->
<!-- <sc ript src="https://api-maps.yandex.ru/2.1/?apikey=657c13da-cbea-44f0-8362-2a2beb55e357&lang=ru_RU"></sc ript> -->

<!-- to will know coords -->
<!-- https://yandex.ru/map-constructor/location-tool/?from=club -->
<style lang="scss">
  .map {
    width: 100%;
    height: 100%;

    .ymaps-2-1-79-map-copyrights-promo,
    .ymaps-2-1-79-copyright,
    .ymaps-2-1-79-copyright_logo_no {
      display: none;
    }
    iframe {
      width: 100%;
    }
  }
</style>
