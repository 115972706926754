<template>
  <section
    class="about-abs"
    id="o-avtoservise"
  >
    <div class="container">
      <div class="about-abs__body">
        <div class="about-abs__content">
          <div class="about-abs__title">{{ aboutBlock.title }}</div>
          <p class="about-abs__text--green">{{ aboutBlock.subtitle }}</p>
          <div
            class="about-abs__texts about-abs__texts--mobile js-about-abs-text-compress"
            v-html="aboutBlock.description"
          ></div>
          <button
            class="about-abs-button-mobile-read-more-texts"
            @click="setHeightTextArea('.about-abs__texts--mobile', $event)"
          >
            <span class="about-abs-button-mobile-read-more-texts__text">Читать далее</span>
            <div class="about-abs-button-mobile-read-more-texts__image">
              <img
                src="@/assets/img/main/about-abs/Chevron_Down_20px.svg"
                alt="arrow"
              />
            </div>
          </button>
          <div class="about-abs__cards">
            <a
              :href="aboutBlock.link_ya"
              class="about-abs__card card-about-abs"
            >
              <div class="card-about-abs__image">
                <img
                  src="@/assets/img/main/about-abs/yandex.svg"
                  alt="yandex"
                />
              </div>
              <div class="card-about-abs__stars">
                <div
                  class="card-about-abs__star"
                  v-for="item in 4"
                >
                  <img
                    src="@/assets/img/main/about-abs/Star1.svg"
                    alt="star"
                  />
                </div>
                <div class="card-about-abs__star">
                  <img
                    src="@/assets/img/main/about-abs/star05.svg"
                    alt="star"
                  />
                </div>
              </div>
            </a>
            <a
              :href="aboutBlock.link_2gis"
              class="about-abs__card card-about-abs"
            >
              <div class="card-about-abs__image">
                <img
                  src="@/assets/img/main/about-abs/2gis.svg"
                  alt="yandex"
                />
              </div>
              <div class="card-about-abs__stars">
                <div
                  class="card-about-abs__star"
                  v-for="item in 5"
                >
                  <img
                    src="@/assets/img/main/about-abs/Star1.svg"
                    alt="star"
                  />
                </div>
              </div>
            </a>
          </div>
        </div>
        <div
          class="about-abs__texts"
          v-html="aboutBlock.description"
        ></div>
      </div>
    </div>
  </section>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    computed: {
      ...mapGetters('about', {
        aboutBlock: 'block',
      }),
    },

    methods: {
      ...mapActions('about', ['GET_ABOUT_BLOCK']),

      setHeightTextArea(selector, $event) {
        const textArea = document.querySelector(selector);
        textArea.classList.remove('js-about-abs-text-compress');
        $event.currentTarget.remove();
      },
    },

    async mounted() {
      await this.GET_ABOUT_BLOCK();
    },
  };
</script>
<style lang="scss">
  @import '@/assets/scss/smart-grid.scss';
  @import '@/assets/scss/mixins.scss';

  .about-abs {
    &__body {
      display: flex;
      padding: 60px 0 80px;
      border-bottom: 1px solid #414141;

      @include md-block() {
        padding: 40px 0;
      }
    }

    &__content {
      margin-right: 160px;

      @include to(1024px) {
        margin-right: 0;
      }
    }

    &__title {
      margin-bottom: 8px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      line-height: 138%;
      color: #ffffff;
      @include af(32, 24);
    }

    &__text--green {
      margin-bottom: 40px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 133%;
      color: #00a19c;
      @include af(24, 16);

      @include sm-block() {
        margin-bottom: 8px;
      }
    }

    &__cards {
      display: flex;
      @include mr(20px);
    }

    &__card {
    }

    &__texts {
      max-width: 590px;
      @include mb(15px);

      @include to(1024px) {
        display: none;
      }

      &--mobile {
        display: none;

        @include to(1024px) {
          display: block;
          margin-bottom: 8px;
          transition: all 0.3s ease 0s;
        }
      }

      p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #898989;

        @include to(1024px) {
          font-size: 14px;
        }
      }
    }
  }

  .card-about-abs {
    display: flex;
    align-items: center;
    padding: 16px;
    border-radius: 16px;
    background: linear-gradient(180deg, rgba(42, 42, 42, 0) 0%, rgba(42, 42, 42, 0.4) 100%);

    @include sm-block() {
      flex-direction: column;
      flex: 1 1 auto;
    }

    &__image {
      margin-right: 36px;

      @include sm-block() {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }

    &__stars {
      display: flex;
    }

    &__star {
    }
  }

  .about-abs-button-mobile-read-more-texts {
    display: none;

    @include to(1024px) {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      border: none;
      background: transparent;

      &__text {
        margin-right: 8px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 114%;
        color: #ffffff;
      }
    }

    &__image {
    }
  }

  .js-about-abs-text-compress {
    display: none;
    height: 60px;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    -ms-line-clamp: 3;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    box-orient: vertical;

    @include to(1024px) {
      display: -webkit-box;
      display: box;
    }
  }
</style>
