<template>
  <main
    class="main"
    ref="main"
  >
    <VServiceSection />

    <div
      v-lazy-load="'VProfitablySection'"
      class="VProfitablySection--wait-lazy-load"
      id="akcii"
    >
      <VProfitablySection v-if="VProfitablySectionVisible" />
    </div>

    <div
      v-lazy-load="'VFullscreenImage'"
      class="VFullscreenImage--wait-lazy-load"
      v-if="!lg"
    >
      <VFullscreenImage v-if="VFullscreenImageVisible" />
    </div>

    <div
      id="raschyot-stoimosti"
      v-lazy-load="'VCostSection'"
      class="VCostSection--wait-lazy-load"
    >
      <VCostSection
        v-if="VCostSectionVisible"
        @onShowDialogPrivacy="$emit('onShowDialogPrivacy')"
      />
    </div>

    <div
      id="uslugi"
      v-lazy-load="'VPopularServices'"
      class="VPopularServices--wait-lazy-load"
    >
      <VPopularServices v-if="VPopularServicesVisible" />
    </div>

    <div
      v-lazy-load="'VConsultSection'"
      class="VConsultSection--wait-lazy-load"
    >
      <VConsultSection
        v-if="VConsultSectionVisible"
        @onShowDialogPrivacy="$emit('onShowDialogPrivacy')"
      />
    </div>

    <VGetCard />

    <div
      v-lazy-load="'VFullscreenImage2'"
      class="VFullscreenImage--wait-lazy-load"
      v-if="!lg"
    >
      <VFullscreenImage2 v-if="VFullscreenImage2Visible" />
    </div>

    <VAboutAbsSection />
    <div
      v-lazy-load="'VReviewsSection'"
      class="VReviewsSection--wait-lazy-load"
    >
      <VReviewsSection v-if="VReviewsSectionVisible" />
    </div>

    <VPartnersSecton />
    <VContactsSection />
  </main>
</template>

<script>
  import computedHeaderHeight from '@/mixins/computedHeaderHeight';
  import VServiceSection from '../blocks/VServiceSection.vue';
  import mobileMixin from '@/mixins/mobileMode';

  // import VProfitablySection from '../blocks/VProfitablySection.vue';
  // import VFullscreenImage from '../blocks/VFullscreenImage.vue';
  import VQuest from '../UI-FC/VQuest.vue';
  // import VCostSection from '../blocks/VCostSection.vue';
  // import VPopularServices from '../blocks/VPopularServices.vue';
  // import VConsultSection from '../blocks/VConsultSection.vue';
  import VGetCard from '../blocks/VGetCard.vue';
  // import VFullscreenImage2 from '../blocks/VFullscreenImage2.vue';
  import VAboutAbsSection from '../blocks/VAboutAbsSection.vue';
  // import VReviewsSection from '../blocks/VReviewsSection.vue';
  import VPartnersSecton from '../blocks/VPartnersSecton.vue';
  import VContactsSection from '../blocks/VContactsSection.vue';

  import lazyLoadDirective from '@/directives/lazy-load';
  import { defineAsyncComponent } from 'vue';

  export default {
    name: 'vMain',
    mixins: [computedHeaderHeight, mobileMixin],
    directives: {
      lazyLoad: lazyLoadDirective,
    },

    components: {
      VServiceSection,
      VProfitablySection: defineAsyncComponent({
        loader: () => import('@/components/blocks/VProfitablySection.vue'),
        loadingComponent: {
          template: '<div class="lazy-block">Loading...</div>',
        } /* shows while loading */,
        // errorComponent: ErrorComponent /* shows if there's an error */,
        // delay: 1000 /* delay in ms before showing loading component */,
        // timeout: 3000 /* timeout after this many ms */,
      }),
      VFullscreenImage: defineAsyncComponent(() => import('@/components/blocks/VFullscreenImage.vue')),
      VQuest,
      VCostSection: defineAsyncComponent(() => import('@/components/blocks/VCostSection.vue')),
      VPopularServices: defineAsyncComponent(() => import('@/components/blocks/VPopularServices.vue')),
      VConsultSection: defineAsyncComponent(() => import('@/components/blocks/VConsultSection.vue')),
      VGetCard,
      VFullscreenImage2: defineAsyncComponent(() => import('@/components/blocks/VFullscreenImage2.vue')),
      VAboutAbsSection,
      VReviewsSection: defineAsyncComponent(() => import('@/components/blocks/VReviewsSection.vue')),
      VPartnersSecton,
      VContactsSection,
    },
    data() {
      return {
        VProfitablySectionVisible: false,
        VFullscreenImageVisible: false,
        VCostSectionVisible: false,
        VPopularServicesVisible: false,
        VReviewsSectionVisible: false,
        VFullscreenImage2Visible: false,
        VConsultSectionVisible: false,
      };
    },
    watch: {
      isMobile() {
        this.setMarginTop();
      },
      isMobileForHead() {
        this.setMarginTop();
      },
    },
    methods: {
      setMarginTop() {
        const main = this.$refs.main;
        if (this.isMobileForHead || true) {
          main.style.marginTop = this.getComputedHeaderHeight();
        } else {
          main.style.marginTop = '';
        }
      },
    },
    beforeCreate() {
      window.addEventListener('load', () => {
        this.setMarginTop();
      });
    },
  };
</script>

<style lang="scss">
  @import '@/assets/scss/smart-grid.scss';

  // .lazy-block {
  //   min-height: 100vh;
  // }

  .main {
    .service {
      margin: 24px 0 40px 0;

      @include md-block() {
        margin: 0;
      }
    }

    .profitably {
      @include md-block() {
        &__body {
          border-bottom: 1px solid #414141;
        }
      }
    }

    .fullscreen-image1,
    .fullscreen-image2 {
      @include lg-block() {
        display: none;
      }
    }
  }

  .VProfitablySection--wait-lazy-load {
    min-height: 515px;
    @include sm-block() {
      min-height: 491px;
    }
  }
  .VFullscreenImage--wait-lazy-load {
    min-height: 396px;
  }
  .VCostSection--wait-lazy-load {
    min-height: 659px;
    @include lg-block() {
      min-height: 762px;
    }
    @include md-block() {
      min-height: 951px;
    }
    @include sm-block() {
      min-height: 867px;
    }
    @include xs-block() {
      min-height: 785px;
    }
  }
  .VPopularServices--wait-lazy-load {
    min-height: 650px;
    @include lg-block() {
      min-height: 964px;
    }
    @include md-block() {
      min-height: 759px;
    }
    @include xs-block() {
      min-height: 787px;
    }
  }
  .VConsultSection--wait-lazy-load {
    min-height: 528px;
    @include lg-block() {
      min-height: 483px;
    }
    @include md-block() {
      min-height: 511px;
    }
    @include xs-block() {
      min-height: 548px;
    }
  }
  .VReviewsSection--wait-lazy-load {
    min-height: 539px;
    @include md-block() {
      min-height: 415px;
    }
  }
  .get-card {
    min-height: 603px;
    @include lg-block() {
      min-height: 501px;
    }
    @include md-block() {
      min-height: 749px;
    }
    @include sm-block() {
      min-height: 752px;
    }
    @include xs-block() {
      min-height: 629px;
    }
  }
  .partners {
    min-height: 328px;
    @include lg-block() {
      min-height: 399px;
    }
    @include md-block() {
      min-height: 462px;
    }
  }
</style>
