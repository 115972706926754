<!-- @input="$parent.$emit('onInput', { value: $event.target.value, index: index })" -->
<!-- @input="$emit('onInput', { value: $event.target.value, index: index })" -->

<!-- <VInput :opts="{ label: 'label', type: 'text', value: 'Mersedes', name: 'auto', placeholder: 'Введите данные автомобиля' }" /> -->
<!-- or -->
<!-- inputOpts: {
    textarea: true,
    cols: 30,
    rows: 5,
    label: "Комментарий",
    type: "textarea",
    name: "problem",
    placeholder: "Опишите проблему",
}, -->
<template>
  <label class="input">
    <span class="input__label">{{ opts.label }}</span>
    <input
      v-if="opts.type === 'tel'"
      @input="onInputTel"
      @keydown="handleKeyDown"
      @focus="onFocus"
      @blur="onBlur"
      @unfocus="onUnfocus"
      class="input__input"
      :type="opts.type"
      :name="opts.name"
      :value="opts.value"
      :index="index"
      :placeholder="opts.placeholder"
      autocomplete="off"
    />
    <textarea
      v-if="opts.textarea"
      @input="onInput"
      class="input__input"
      :type="opts.type"
      :value="opts.value"
      :name="opts.name"
      :cols="opts.cols"
      :rows="opts.rows"
      :placeholder="opts.placeholder"
      :maxlength="opts.maxlength"
    >
    </textarea>
    <input
      v-if="opts.type === 'text'"
      @input="onInput"
      class="input__input"
      :pattern="opts.pattern"
      :type="opts.type"
      :name="opts.name"
      :value="opts.value"
      :index="index"
      :placeholder="opts.placeholder"
      :maxlength="opts.maxlength"
      autocomplete="off"
    />
  </label>
</template>

<script>
  import { onMounted } from 'vue';

  export default {
    name: 'VInput',
    props: {
      opts: {
        type: Object,
      },
      index: {
        type: Number,
      },
    },
    data() {
      return {
        template: '+7 (***) ***-**-**',
        str: '',
        arrayChars: [],
        numbersObj: {},
        backSpace: false,
        // one: [],
        // two: [],
        // three: [],
        // four: [],
      };
    },
    methods: {
      onFocus(e) {
        if (e.target.value.length < 4) {
          e.target.value = '+7 (';
        }
      },
      onBlur(e) {
        if (e.target.value.length <= 4) {
          e.target.value = '';
        }
      },
      handleKeyDown(event) {
        if (event.keyCode === 8) {
          this.backSpace = true;
        } else {
          this.backSpace = false;
        }
        if ((event.keyCode < 48 || event.keyCode > 57) && event.keyCode !== 8 && event.keyCode !== 9 && (event.keyCode < 37 || event.keyCode > 40) && (event.keyCode < 96 || event.keyCode > 105)) {
          event.preventDefault();
        }
      },
      onInput(e) {
        this.$emit('onInput', e.target.value.trim());
      },

      onInputTel(e) {
        // e.target.value = e.target.value.replace(this.opts.pattern, '')
        // const input = e.target
        // let value = e.target.value
        // const cursorPos = input.selectionStart
        // this.$emit('onInput', e.target.value)

        let cursorPosition = e.target.selectionStart;
        let currentSymbol = e.target.value[cursorPosition - 1];
        if (isNaN(+currentSymbol)) {
          currentSymbol = '';
        }

        if (cursorPosition < 4) {
          e.target.value = '+7 (';
        }

        function numbersOfPhone() {
          const string = e.target.value;
          const digitsArray = string.match(/\d+/g);
          const str = digitsArray.join('');
          const result = str.split('');
          return result;
        }

        const numbers = numbersOfPhone();

        // || this.template.length < e.target.value.length

        if ((!isNaN(+currentSymbol) && !this.backSpace && cursorPosition == e.target.value.length) || numbers.length >= 11) {
          let starIndex = this.template.indexOf('*', cursorPosition);
          if (numbers.length >= 11) {
            starIndex = this.template.length;
          }

          // this.numbersObj[starIndex] = currentSymbol
          // const keysForNumber = Object.keys(this.numbersObj)

          this.arrayChars = this.template.split('');
          this.str = '';

          for (let j = 1, i = 0; i < starIndex; i++) {
            if (this.arrayChars[i] === '*') {
              this.str += numbers[j];
              j += 1;
            } else {
              this.str += this.arrayChars[i];
            }
          }

          if (starIndex !== -1) {
            e.target.value = this.str;
          }
          // } else {
          // 	e.target.value = e.target.value.replace(this.opts.pattern, '')

          // }

          this.$emit('onInput', e.target.value);
        } else {
          this.$emit('onInput', e.target.value);
        }
      },
    },
  };
</script>

<style lang="scss">
  .input {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__label {
      margin-bottom: 4px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 133%;
      color: #999999;
    }

    &__input {
      padding: 12px 16px;
      outline: none;
      border: none;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 143%;
      color: #ffffff;

      border-radius: 4px;
      background: #717171;

      &::placeholder {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #cbcccc;
      }
    }

    textarea {
      resize: none;
      height: 80px;
      overflow-y: auto;
    }

    textarea {
      scrollbar-width: none;
      /* for Firefox */
      -ms-overflow-style: none;
      /* for Internet Explorer and Edge */
      overflow: -moz-scrollbars-none;
      /* for older versions of Firefox */
    }

    textarea::-webkit-scrollbar {
      display: none;
      /* for Chrome and Safari */
    }
  }
</style>
