<template>
  <div class="card-dialog">
    <div class="container">
      <div class="card-dialog__blur"></div>
      <div class="card-dialog__wrapper">
        <button
          class="card-dialog__close"
          @click="onHideDialog"
        ></button>
        <div class="card-dialog__body">
          <div class="card-dialog__content content-card-dialog">
            <div class="content-card-dialog__top">
              <h6 class="content-card-dialog__title">{{ opts.title }}</h6>
              <p class="content-card-dialog__text">Оставьте заявку или просто напишите нам в мессенджеры</p>
            </div>
          </div>
          <div class="card-dialog__form">
            <VForm
              :formData="dialogForData"
              @onInput="onInput"
              @onSendFormData="onSendFormData"
            />
          </div>
        </div>
        <div class="card-dialog__image">
          <picture>
            <source
              type="image/webp"
              srcset="@/assets/img/main/consult/consult_image.webp"
            />
            <source
              type="image/png"
              srcset="@/assets/img/main/consult/consult_image.png"
            />
            <img
              src="@/assets/img/main/consult/consult_image.png"
              alt="consult"
            />
          </picture>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import VForm from './VForm.vue';

  export default {
    name: 'VCardDialog',
    components: { VForm },
    props: {
      opts: Object,
    },

    data() {
      return {
        dialogForData: {
          inputs: [
            { value: '', type: 'text', label: 'Имя', name: 'name', placeholder: 'Введите имя', maxlength: 32 },
            {
              value: '',
              type: 'tel',
              name: 'phone',
              label: 'Телефон',
              placeholder: '+7 (___) ___-__-__',
              pattern: /[^0-9+]/g,
            },
          ],
          buttonText: 'Оставить заявку',
        },
      };
    },

    computed: {
      ...mapGetters('form', {
        messageSent: 'success',
      }),
    },

    methods: {
      ...mapActions('form', ['SEND_USER_DATA', 'SET_SUCCESS']),

      onHideDialog() {
        this.$emit('onHideDialog');
      },
      onInput(e) {
        this.dialogForData.inputs[e.index].value = e.value;
      },
      async onSendFormData() {
        const userData = {
          from: this.opts.from,
          name: this.dialogForData.inputs[0].value,
          phone: this.dialogForData.inputs[1].value,
          formname: this.opts.title,
        };
        await this.SEND_USER_DATA(userData);
        this.SET_SUCCESS();
      },
    },
  };
</script>

<style lang="scss">
  @import '@/assets/scss/mixins.scss';
  @import '@/assets/scss/smart-grid.scss';

  .card-dialog {
    .container {
      position: relative;
      padding: 0;
      width: 996px;

      @include xl-block() {
        display: flex;
        justify-content: center;
        width: auto;
      }
    }
    &__wrapper {
      position: relative;

      background: #2a2a2a;
      border-radius: 16px;
      padding: 60px;
      @include md-block() {
        padding: 20px;
        padding-top: 60px;
      }
    }

    &__blur {
      position: absolute;
      width: 155px;
      height: 155px;
      right: -30px;
      top: -50px;
      background: #00a19c;
      filter: blur(105px);
    }

    &__close {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10;
      padding: 26px;
      border: none;
      background: #111212;
      border-width: 0px 0px 1px 1px;
      border-style: solid;
      border-color: #2a2a2a;
      border-radius: 0px 16px;

      &::after,
      &::before {
        content: '';
        display: block;
        width: 15px;
        height: 2px;
        background: #717171;
        position: absolute;
        left: 50%;
        top: 50%;
      }

      &::before {
        transform: translate(-50%, 50%) rotate(-45deg);
      }

      &::after {
        transform: translate(-50%, 50%) rotate(45deg);
      }
    }

    &__body {
    }

    &__image {
      position: absolute;
      right: 0;
      bottom: 0;

      @include xl-block() {
        display: none;
      }
    }

    &__content {
    }

    &__form {
      max-width: 470px;

      .form {
        &__title {
        }

        &__body {
        }

        &__inputs {
          display: flex;
          margin-bottom: 20px;

          @include mr(20px);

          @include xl-block() {
            @include mr(0px);
            @include mb(20px);

            flex-direction: column;
          }
        }

        &__input {
          flex: 1 1 50%;
        }

        &__bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;

          @include mr(30px);

          @include md-block() {
            @include mr(0px);
            @include mb(30px);
            flex-direction: column;
            justify-content: start;
            align-items: flex-start;
          }
        }

        &__bottom-text {
          max-width: 261px;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 133%;
          color: #999999;
        }

        &__button {
        }
      }
    }
  }

  .content-card-dialog {
    &__top {
      max-width: 548px;
      margin-bottom: 20px;
    }

    &__title {
      margin-bottom: 8px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      line-height: 138%;
      color: #ffffff;
      @include af(32, 21);
    }

    &__text {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #898989;
    }

    &__text-bottom {
    }
  }
</style>
