<template>
  <div class="card-dialog card-dialog--privacy">
    <div class="container">
      <div class="card-dialog__blur"></div>
      <div class="card-dialog__wrapper">
        <button
          class="card-dialog__close"
          @click="onHideDialog"
        ></button>
        <div class="card-dialog__body content-card-dialog">
          <h3 class="content-card-dialog__title">{{ privacyBlock.name }}</h3>
          <div
            class="content-card-dialog__text"
            v-html="privacyBlock.description"
          ></div>
        </div>
        <!-- <div class="card-dialog__image">
          <img
            src="@/assets/img/modal-window/modal-image.png"
            alt="phone"
          />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'VCardDialogPrivacy',
    props: {
      opts: Object,
    },

    data() {
      return {};
    },
    computed: {
      ...mapGetters('privacy', {
        privacyBlock: 'block',
      }),
    },

    methods: {
      ...mapActions('privacy', ['GET_PRIVACY_BLOCK']),

      onHideDialog() {
        this.$emit('onHideDialog');
      },
    },

    async mounted() {
      await this.GET_PRIVACY_BLOCK();
    },
  };
</script>

<style lang="scss">
  @import '@/assets/scss/mixins.scss';
  @import '@/assets/scss/smart-grid.scss';
  .card-dialog--privacy {
    .content-card-dialog__title {
      margin-bottom: 30px;
    }
    .content-card-dialog__text {
      max-height: 383px;
      overflow-y: auto;
      @include mb(20px);
      @include hideScroll();
    }
  }

  // .card-dialog {
  //   .container {
  //     position: relative;

  //     max-width: 996px;

  //     @include xl-block() {
  //       display: flex;
  //       justify-content: center;
  //     }
  //   }
  //   &__wrapper {
  //     position: relative;

  //     background: #2a2a2a;
  //     border-radius: 16px;
  //     padding: 60px;
  //     @include md-block() {
  //       padding: 20px;
  //       padding-top: 60px;
  //     }
  //   }

  //   &__blur {
  //     position: absolute;
  //     width: 155px;
  //     height: 155px;
  //     right: -30px;
  //     top: -50px;
  //     background: #00a19c;
  //     filter: blur(105px);
  //   }

  //   &__close {
  //     position: absolute;
  //     right: 0;
  //     top: 0;
  //     z-index: 10;
  //     padding: 26px;
  //     border: none;
  //     background: #111212;
  //     border-width: 0px 0px 1px 1px;
  //     border-style: solid;
  //     border-color: #2a2a2a;
  //     border-radius: 0px 16px;

  //     &::after,
  //     &::before {
  //       content: '';
  //       display: block;
  //       width: 15px;
  //       height: 2px;
  //       background: #717171;
  //       position: absolute;
  //       left: 50%;
  //       top: 50%;
  //     }

  //     &::before {
  //       transform: translate(-50%, 50%) rotate(-45deg);
  //     }

  //     &::after {
  //       transform: translate(-50%, 50%) rotate(45deg);
  //     }
  //   }

  //   &__body {
  //   }

  //   &__image {
  //     position: absolute;
  //     right: 0;
  //     bottom: 0;

  //     @include xl-block() {
  //       display: none;
  //     }
  //   }

  //   &__content {
  //   }

  //   &__form {
  //     max-width: 470px;

  //     .form {
  //       &__title {
  //       }

  //       &__body {
  //       }

  //       &__inputs {
  //         display: flex;
  //         margin-bottom: 20px;

  //         @include mr(20px);

  //         @include lg-block() {
  //           @include mr(0px);
  //           @include mb(20px);

  //           flex-direction: column;
  //         }
  //       }

  //       &__input {
  //         flex: 1 1 50%;
  //       }

  //       &__bottom {
  //         display: flex;
  //         align-items: center;
  //         justify-content: space-between;

  //         @include mr(30px);

  //         @include md-block() {
  //           @include mr(0px);
  //           @include mb(30px);
  //           flex-direction: column;
  //           justify-content: start;
  //           align-items: flex-start;
  //         }
  //       }

  //       &__bottom-text {
  //         max-width: 261px;
  //         font-family: 'Roboto';
  //         font-style: normal;
  //         font-weight: 400;
  //         font-size: 12px;
  //         line-height: 133%;
  //         color: #999999;
  //       }

  //       &__button {
  //       }
  //     }
  //   }
  // }

  // .content-card-dialog {
  //   &__top {
  //     max-width: 548px;
  //     margin-bottom: 20px;
  //   }

  //   &__title {
  //     margin-bottom: 8px;
  //     font-family: 'Roboto';
  //     font-style: normal;
  //     font-weight: 700;
  //     line-height: 138%;
  //     color: #ffffff;
  //     @include af(32, 21);
  //   }

  //   &__text {
  //     font-family: 'Roboto';
  //     font-style: normal;
  //     font-weight: 400;
  //     font-size: 16px;
  //     line-height: 150%;
  //     color: #898989;
  //   }

  //   &__text-bottom {
  //   }
  // }
</style>
