<template>
  <div class="wrapper">
    <vHeader
      :showMobileMenu="showMobileMenu"
      @onGoToAnchor="onGoToAnchor"
      @onShowMobileMenu="onShowMobileMenu"
      @onShowDialog="onShowDialog"
    />
    <VMain
      @onShowDialog="onShowDialog"
      @onSendFormData="onSendFormData"
      @onShowDialogPrivacy="onShowDialogPrivacy"
    />
    <VFooter
      @onGoToAnchor="onGoToAnchor"
      @onShowDialogPrivacy="onShowDialogPrivacy"
    />
    <Transition
      name="fade"
      v-lazy-load="'VModalWindowUIFC'"
    >
      <VModalWindowUIFC
        v-if="showDialog"
        :showDialog="showDialog"
        @onShowDialogPrivacy="onShowDialogPrivacy"
        @onHideDialog="onHideDialog"
      >
        <VCardDialog
          v-if="!messageSent"
          @onHideDialog="onHideDialog"
          :opts="cardDialogData"
        />
        <VCardDialogSent
          v-else
          @onHideDialog="onHideDialog"
        />
      </VModalWindowUIFC>
    </Transition>
    <Transition
      name="fade"
      v-lazy-load="'VModalWindowUIFC'"
    >
      <VModalWindowUIFC
        v-if="showDialogPrivacy"
        :showDialog="showDialogPrivacy"
        @onHideDialog="onHideDialog"
      >
        <VCardDialogPrivacy @onHideDialog="onHideDialog" />
      </VModalWindowUIFC>
    </Transition>
  </div>
</template>
<script>
  import axios from 'axios';

  import lazyLoadDirective from '@/directives/lazy-load';
  import { defineAsyncComponent } from 'vue';

  import { mapGetters, mapActions } from 'vuex';

  import mobileMixin from './mixins/mobileMode';
  import bodyLockMixin from '@/mixins/bodyLock';
  import vHeader from './components/layout/vHeader.vue';
  import VMain from './components/layout/vMain.vue';
  import VFooter from './components/layout/vFooter.vue';
  // import VModalWindowUIFC from './components/UI-FC/VModalWindowUIFC.vue';
  import VCardDialog from './components/elements/VCardDialog.vue';
  import VCardDialogPrivacy from './components/elements/VCardDialogPrivacy.vue';
  import VCardDialogSent from './components/elements/VCardDialogSent.vue';

  export default {
    mixins: [mobileMixin, bodyLockMixin],
    directives: {
      lazyLoad: lazyLoadDirective,
    },
    components: {
      vHeader,
      VMain,
      VFooter,
      VModalWindowUIFC: defineAsyncComponent(() => import('@/components/UI-FC/VModalWindowUIFC.vue')),
      VCardDialog,
      VCardDialogPrivacy,
      VCardDialogSent,
    },
    data() {
      return {
        showMobileMenu: false,
        showDialog: false,
        showDialogPrivacy: false,
        cardDialogData: {},
        headerData: {},
        headerScript: {},
        bodyScript: {},
      };
    },
    methods: {
      ...mapActions('form', ['RESET_SUCCESS']),

      onShowMobileMenu() {
        if (this.showDialog) {
          this.showDialog = false;
        }
        this.showMobileMenu = !this.showMobileMenu;
      },
      onShowDialog(e) {
        if (this.showMobileMenu) {
          this.showMobileMenu = false;
        }
        if (this.showDialogPrivacy) {
          this.showDialogPrivacy = false;
        }

        this.cardDialogData = e;
        this.showDialog = true;
        this.lockBody();
      },
      onShowDialogPrivacy() {
        if (this.showMobileMenu) {
          this.showMobileMenu = false;
        }
        if (this.showDialog) {
          this.showDialog = false;
        }
        this.showDialogPrivacy = true;
        this.lockBody();
      },

      onHideDialog() {
        this.showDialog = false;
        this.showDialogPrivacy = false;
        this.RESET_SUCCESS();
        this.unlockBody();
      },
      onGoToAnchor() {
        this.showMobileMenu = false;
      },
      onSendFormData(userData) {
        // axios.get('https://api.abs-autoservice.ru/api/contacts?format=json')
        // axios.get("https://api.abs-autoservice.ru/send/", {
        // 	params: {
        // 		name: userData.name,
        // 		phone: userData.phone,
        // 		formname: userData.formname
        // 	}
        // }).then(response => {
        // })
      },

      loadScriptHeader() {
        axios.get('https://api.abs-autoservice.ru/api/couters_header?format=json').then((response) => {
          this.headerScript = response.data.counter;
          this.headerScript.forEach((script) => {
            if (script.nocodeyes == '1') {
              const noscriptElement = document.createElement('noscript');
              noscriptElement.innerHTML = script.nocod;
              const firstElement = document.head.firstChild;
              document.head.insertBefore(noscriptElement, firstElement);
            }
            const scriptElement = document.createElement('script');
            scriptElement.innerHTML = script.cod;
            const firstElement = document.head.firstChild;
            document.head.insertBefore(scriptElement, firstElement);
            // document.head.appendChild(scriptElement);
          });
        });
        axios.get('https://api.abs-autoservice.ru/api/couters_body?format=json').then((response) => {
          this.bodyScript = response.data.counter;
          this.bodyScript.forEach((script) => {
            if (script.nocodeyes == '1') {
              const noscriptElement = document.createElement('noscript');
              noscriptElement.innerHTML = script.nocod;
              const firstElement = document.body.firstChild;
              document.body.insertBefore(noscriptElement, firstElement);
            }
            const scriptElement = document.createElement('script');
            scriptElement.innerHTML = script.cod;
            const firstElement = document.body.firstChild;
            document.body.insertBefore(scriptElement, firstElement);
            // document.head.appendChild(scriptElement);
          });
        });
      },
    },
    computed: {
      ...mapGetters('menu', {
        menuList: 'items',
      }),
      ...mapGetters('products', {
        productList: 'items',
      }),
      ...mapGetters('form', {
        messageSent: 'success',
      }),
    },
    async mounted() {
      await axios
        .get('https://api.abs-autoservice.ru/api/header?format=json')
        .then((response) => {
          document.getElementById('page-title').textContent = response.data.title;
          document.getElementById('page-description').content = response.data.description;
          document.getElementById('page-keywords').content = response.data.metakey;
        })
        .catch((error) => {});
      this.loadScriptHeader();
    },
  };
</script>
<style lang="scss">
  @import '@/assets/scss/settings.scss';

  #app {
    height: 100%;
  }

  body {
    background: #111212;
  }

  .fade-enter-active {
    transition: all 0.3s ease 0s;
  }

  .fade-enter-from,
  .fade-leave-to {
    transition: all 0.3s ease 0s;

    opacity: 0;
  }
</style>
