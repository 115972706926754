<template>
  <a
    href="#service"
    class="logo"
    @click.prevent="goToAnchorFromHeaderLogo"
  >
    <div class="logo__body">
      <div class="logo__image">
        <picture>
          <source
            media="(min-width: 991px)"
            srcset="@/assets/img/header/logo/Logo.svg"
          />
          <source
            media="(max-width: 992px)"
            srcset="@/assets/img/header/logo/Logo_small.svg"
          />
          <img
            src="@/assets/img/header/logo/Logo.svg"
            alt="image"
          />
        </picture>
      </div>
    </div>
  </a>
</template>

<script>
  export default {
    methods: {
      goToAnchorFromHeaderLogo() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
    },
  };
</script>

<style lang="scss">
  .logo {
    &__body {
    }

    &__image {
    }
  }
</style>
