<template>
  <div class="question">
    <h6 class="question__title">{{ opts.title }}</h6>
    <div class="question__textarea">
      <VInput
        @onInput="$emit('onInput', { value: $event })"
        :opts="opts.inputOpts"
      />
    </div>
  </div>
</template>

<script>
  import VInput from '../UI/VInput.vue';

  export default {
    name: 'qu2',
    components: { VInput },
    props: {
      opts: {
        type: Object,
        required: true,
      },
    },
  };
</script>

<style lang="scss">
  @import '@/assets/scss/mixins.scss';
  @import '@/assets/scss/smart-grid.scss';

  .question {
    &__textarea {
      max-width: 512px;
      margin-bottom: 28px;
    }
  }
</style>
