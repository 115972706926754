<template>
  <section
    class="service"
    id="service"
  >
    <div class="service__blur--right"></div>
    <div class="service__blur--left"></div>

    <div class="container service__container">
      <div class="service__body ibg">
        <div class="service__image">
          <!-- <img
            :src="`https://api.abs-autoservice.ru/${bannerBlock.image}`"
            alt="image"
          /> -->

          <picture>
            <source
              type="image/webp"
              srcset="@/assets/img/main/service/bg.webp, @/assets/img/main/service/bg_sm.webp 576w"
            />
            <source
              type="image/jpeg"
              srcset="@/assets/img/main/service/bg.jpg, @/assets/img/main/service/bg_sm.jpg 576w"
            />
            <img
              src="@/assets/img/main/service/bg.jpg"
              alt="Your alternate text"
            />
          </picture>
        </div>
        <div class="service__content content-service">
          <div class="content-service__texts">
            <h1 class="content-service__title">{{ bannerBlock.name }}</h1>
            <ul class="content-service__list list-content-service">
              <li
                class="list-content-service__item"
                v-for="item in [bannerBlock.text1, bannerBlock.text2, bannerBlock.text3]"
                :key="item"
              >
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="content-service__buttons buttons-content-service">
            <div class="buttons-content-service__button buttons-content-service--bg">
              <VButton @click="goToAnchor('raschyot-stoimosti')">{{ bannerBlock.button1 }}</VButton>
            </div>
            <div class="buttons-content-service__button buttons-content-service--bd">
              <VButton @click="onShowDialog">{{ bannerBlock.button2 }}</VButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import computedHeaderHeight from '@/mixins/computedHeaderHeight';
  import VButton from '../UI/VButton.vue';

  export default {
    name: 'VServiceSection',
    mixins: [computedHeaderHeight],
    computed: {
      ...mapGetters('banner', {
        bannerBlock: 'block',
      }),
    },
    methods: {
      ...mapActions('banner', ['GET_BANNER_BLOCK']),

      goToAnchor(anchorId) {
        let computedHeaderHeight = parseInt(this.getComputedHeaderHeight());
        computedHeaderHeight = computedHeaderHeight - 2;

        scrollToAnchor(anchorId, computedHeaderHeight);

        function scrollToAnchor(anchorId, headerOffset) {
          const anchor = document.getElementById(anchorId);

          if (anchor) {
            const scrollTop = anchor.getBoundingClientRect().top + window.pageYOffset - headerOffset;

            window.scrollTo({ top: scrollTop, behavior: 'smooth' });
          }
        }
      },
      onShowDialog() {
        this.$parent.$emit('onShowDialog', { from: this, title: 'Запишитесь на сервис' });
      },
    },
    components: { VButton },
    async mounted() {
      await this.GET_BANNER_BLOCK();
    },
  };
</script>

<style lang="scss">
  @import '@/assets/scss/smart-grid.scss';
  @import '@/assets/scss/mixins.scss';

  $bg: rgba(17, 18, 18, 0.8);
  $bd: #2a2a2a;

  .service {
    position: relative;
    min-height: 421px;

    &__blur--left {
      position: absolute;
      width: 95px;
      height: 95px;
      left: 244px;
      bottom: 125px;
      background: #00a19c;
      filter: blur(70px);
    }

    &__blur--right {
      position: absolute;
      width: 155px;
      height: 155px;
      right: -35px;
      top: -50px;
      background: #00a19c;
      filter: blur(105px);
    }
    &__body {
      padding: 80px 80px 144px;
      border-radius: 24px;
      overflow: hidden;

      @include md-block() {
        padding: 24px 0 40px 0;
      }

      @include sm-block() {
        margin: 0 -20px;
        padding: 24px 20px 40px 20px;
      }
    }

    & &__container {
      max-width: 1350px;
    }

    &__image {
    }

    &__content {
      position: relative;
    }
  }

  .content-service {
    &__texts {
      margin-bottom: 49px;
      max-width: 689px;

      @include xl-block() {
        max-width: 450px;
      }

      @include lg-block() {
        max-width: 400px;
      }

      @include md-block() {
        max-width: 280px;
        margin-bottom: 40px;
      }
    }

    &__title {
      margin-bottom: 16px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      color: #ffffff;
      @include af(40, 24);
    }

    &__list {
    }

    &__buttons {
      display: flex;
      @include mr(20px);

      @include sm-block() {
        @include mr(0px);
        @include mb(20px);
        flex-direction: column;

        & .button {
          width: 100%;
        }
      }
    }
  }

  .list-content-service {
    display: flex;
    max-width: 560px;
    border-top: 1px solid $bd;
    border-bottom: 1px solid $bd;

    @include xl-block() {
      flex-direction: column;
      align-items: start;
      @include mb(4px);
      border: none;
    }

    &__item {
      white-space: nowrap;
      padding: 6px 18px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      color: #e5e6e5;
      @include af(16, 14);

      @include xl-block() {
        padding: 4px 12px;
        background: rgba(42, 42, 42, 0.7);
        backdrop-filter: blur(4px);
        border-radius: 40px;
      }
    }
  }

  .buttons-content-service {
    &__button {
      .button {
        padding: 12px 22px;
      }
    }
  }

  .buttons-content-service--bg {
  }

  .buttons-content-service--bd {
    .button {
      background: transparent;
      transition: all 0.3s ease 0s;
      &:hover {
        background: #00a19c;
        transition: all 0.3s ease 0s;
      }
    }
  }
</style>
