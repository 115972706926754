<template>
  <section
    class="contacts"
    id="kontakty"
  >
    <div class="container">
      <div class="contacts__body">
        <div class="contacts__content">
          <h2 class="contacts__title">{{ contactsBlock.name }}</h2>
          <ul class="contacts__info info-contacts">
            <li class="info-contacts__item">{{ contactsBlock.address }}</li>
            <li class="info-contacts__item">
              <VTel
                v-if="contactsBlock.phone"
                :phoneNumber="contactsBlock.phone"
                :phoneLink="contactsBlock.phone_link"
              />
            </li>
            <li class="info-contacts__item">{{ contactsBlock.work }}</li>
            <li class="info-contacts__item">
              <VEmail
                v-if="contactsBlock.email"
                :email="contactsBlock.email"
              />
            </li>
          </ul>
          <div
            class="contacts__button"
            @click="sendToScriptsCounter"
          >
            <VButton
              tag="a"
              target="_blank"
              href="https://yandex.ru/maps/?rtext=~45.01153507458498, 41.92907249999995"
              >Построить маршрут
            </VButton>
          </div>
        </div>
        <div class="contacts__map">
          <VMapUIFC
            v-lazy-load
            :latitude="contactsBlock.latitude"
            :longitude="contactsBlock.longitude"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import VMapUIFC from '../UI-FC/VMapUIFC.vue';
  import VButton from '../UI/VButton.vue';
  import VEmail from '../UI/VEmail.vue';
  import VTel from '../UI/VTel.vue';
  import { mapActions, mapGetters } from 'vuex';
  import lazyLoadDirective from '@/directives/lazy-load';

  // computed: {
  // 	...mapGetters('partners', {
  // 		partnersItems: 'items'
  // 	})
  // },

  // methods: {
  // 	...mapActions('partners', ['GET_PARTNERS_ITEMS'])
  // },

  // async mounted() {
  // 	await this.GET_PARTNERS_ITEMS()
  // 	console.log(this.partnersItems);
  // }

  export default {
    name: 'VContactsSection',
    directives: {
      lazyLoad: lazyLoadDirective,
    },
    computed: {
      ...mapGetters('contacts', {
        contactsBlock: 'block',
      }),
    },
    methods: {
      ...mapActions('contacts', ['GET_CONTACTS_BLOCK']),

      sendToScriptsCounter() {
        ym(93722392, 'reachGoal', 'route_footer');
        return true;
      },
    },
    components: { VTel, VEmail, VButton, VMapUIFC },

    async mounted() {
      await this.GET_CONTACTS_BLOCK();
    },
  };
</script>
<style lang="scss">
  @import '@/assets/scss/smart-grid.scss';
  @import '@/assets/scss/mixins.scss';

  .contacts {
    &__body {
      display: flex;
      padding: 60px 0 80px;

      @include lg-block() {
        flex-direction: column;
      }

      @include md-block() {
        padding: 40px 0;
      }
    }

    &__content {
      flex: 0 0 261px;
      margin-right: 12.25%;

      @include lg-block() {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }

    &__title {
      margin-bottom: 32px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 138%;
      color: #ffffff;
    }

    &__info {
      margin-bottom: 26px;
    }

    &__button {
      .button {
        display: inline-block;
        padding: 12px 22px;
        @include lg-block() {
          display: flex;
        }
      }
    }

    &__map {
      flex: 1 1 auto;
      height: 337px;
      border-radius: 16px;
      overflow: hidden;

      @include lg-block() {
        padding-top: 35%;
        position: relative;

        .map {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }

      @include md-block() {
        padding-top: 45%;
      }

      @include sm-block() {
        margin: 0 -20px;
        padding-top: 71.43%;
      }
    }
  }

  .info-contacts {
    @include mb(16px);

    &__item {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 133%;
      color: #898989;
      font-stretch: 100;

      .email {
        text-decoration-line: underline;
      }

      a {
        transition: all 0.3s ease 0s;

        &:hover {
          transition: all 0.3s ease 0s;
          color: #ffffff;
        }
      }
    }
  }
</style>
