<!-- @onInput="$emit('onInput', $event)" -->
<!-- @input="$emit('onInput', { value: $event.target.value, index: index })" -->

<template>
  <form
    class="form"
    @submit.prevent="$emit('onSendFormData')"
  >
    <h5
      class="form__title"
      v-if="formData.title"
    >
      {{ formData.title }}
    </h5>
    <div class="form__body">
      <div class="form__inputs">
        <div
          class="form__input"
          v-for="(inputData, index) in formData.inputs"
          :key="inputData.name"
        >
          <VInput
            :opts="inputData"
            @onInput.trim="$emit('onInput', { value: $event, index: index })"
            :index="index"
          />
        </div>
      </div>
      <div class="form__bottom">
        <button
          type="button"
          class="form__bottom-text"
          @click="$parent.$parent.$emit('onShowDialogPrivacy')"
        >
          Нажимая кнопку, вы соглашаетесь с нашей
          <span
            class="form__bottom-text-link"
            @click="onShowDialogPrivacy"
          >
            Политикой конфиденциальности
          </span>
        </button>
        <div class="form__button">
          <VButton :disabled="!isValid">
            <span v-if="!messagePending">{{ formData.buttonText }}</span>
            <span
              v-else
              class="loading"
            ></span>
          </VButton>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
  import { mapGetters } from 'vuex';
  import VButton from '../UI/VButton.vue';
  import VInput from '../UI/VInput.vue';

  export default {
    name: 'VForm',
    components: {
      VInput,
      VButton,
    },
    props: {
      formData: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        // valueName: '',
        // valueTel: '',
        isValid: false,
      };
    },
    // methods: {
    //   onInput(e, index) {
    //     if (index === 0) {
    //       this.valueName = e;
    //     }
    //     if (index === 1) {
    //       this.valueTel = e;
    //     }
    //     if (this.valueName && this.valueTel) {
    //       this.isValid = this.valueName.length >= 3 && this.valueTel.length === 18;
    //     }
    //   },
    // },
    computed: {
      ...mapGetters('form', {
        // messageSent: 'success',
        messagePending: 'pending',
      }),
    },
    watch: {
      formData: {
        deep: true,
        handler(newValue, oldValue) {
          const valueName = newValue.inputs[0].value;
          const valueTel = newValue.inputs[1].value;
          this.isValid = valueName.length >= 2 && valueTel.length === 18;
        },
      },
    },
  };
  // exampleFormData : {
  //   title: 'Узнайте свободные даты и забронируйте зал',
  //     inputs: [
  //       { type: 'text', label: 'Ваше имя', name: "name", value: "Иван", placeholder: 'Имя' },
  //       { type: 'tel', label: 'Ваш номер телефона', name: "tel", placeholder: "+7 (___) ___-__-__" }
  //     ]
  // }
</script>

<style lang="scss">
  .form {
    &__title {
    }

    &__body {
    }

    &__inputs {
    }

    &__input {
    }

    &__bottom {
    }

    &__bottom-text {
      cursor: auto;
      text-align: left;
      border: none;
      background: transparent;
    }
    &__bottom-text-link {
      cursor: pointer;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }

    &__button {
      .button {
        min-width: 158px;
        max-height: 44.83px;
      }
    }
  }

  .loading {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 4px solid #fff;
    border-radius: 50%;
    border-left-color: #26b6f8;
    animation: load 1.5s linear infinite;
  }

  @keyframes load {
    to {
      transform: rotate(360deg);
    }
  }
</style>
