<template>
  <a
    class="tel"
    :href="`tel:${phoneLink}`"
  >
    {{ phoneNumber }}
  </a>
</template>

<script>
  export default {
    props: {
      phoneNumber: {
        type: String,
        required: true,
      },
      phoneLink: {
        type: String,
      },
    },
    computed: {
      // numbersOfPhone() {
      //   const string = this.phoneNumber;
      //   const digitsArray = string.match(/\d+/g);
      //   // if (digitsArray[0] === 8) {
      //   //   digitsArray[0] = 7;
      //   // }
      //   const numbers = digitsArray.join('');
      //   return numbers;
      // },
    },
  };
</script>

<style lang="scss">
  .tel {
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }
</style>
