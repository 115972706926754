<template>
  <div class="card-dialog">
    <div class="container">
      <div class="card-dialog__blur"></div>
      <div class="card-dialog__wrapper">
        <button
          class="card-dialog__close"
          @click="onHideDialog"
        ></button>
        <div class="card-dialog__body">
          <div class="card-dialog__content content-card-dialog">
            <div class="content-card-dialog__top">
              <h6 class="content-card-dialog__title">Спасибо!</h6>
              <h6 class="content-card-dialog__title">Ваша заявка отправлена успешно.</h6>
              <p class="content-card-dialog__text--green">Специалист свяжется с Вами в ближайшее время.</p>
            </div>
          </div>
        </div>
        <div class="card-dialog__image">
          <img
            src="@/assets/img/modal-window/modal-image.png"
            alt="phone"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  // import VForm from './VForm.vue';

  export default {
    name: 'VCardDialogSent',
    // components: { VForm },
    // props: {
    //   opts: Object,
    // },

    data() {
      return {
        dialogForData: {
          inputs: [
            { value: '', type: 'text', label: 'Имя', name: 'name', placeholder: 'Введите имя' },
            {
              value: '',
              type: 'tel',
              name: 'phone',
              label: 'Телефон',
              placeholder: '+7 (___) ___-__-__',
              pattern: /[^0-9+]/g,
              maxlength: 12,
            },
          ],
          buttonText: 'Оставить заявку',
        },
      };
    },

    computed: {
      ...mapGetters('form', {
        messageSent: 'success',
      }),
    },

    methods: {
      ...mapActions('form', ['SEND_USER_DATA']),

      onHideDialog() {
        this.$emit('onHideDialog');
      },
      onInput(e) {
        this.dialogForData.inputs[e.index].value = e.value;
      },
      async onSendFormData() {
        const userData = {
          name: this.dialogForData.inputs[0].value,
          phone: this.dialogForData.inputs[1].value,
          formname: this.opts.title,
        };

        await this.SEND_USER_DATA(userData);
      },
    },
  };
</script>

<style lang="scss">
  @import '@/assets/scss/mixins.scss';
  @import '@/assets/scss/smart-grid.scss';

  .card-dialog {
    &__body {
      min-height: 225px;
    }
  }

  .content-card-dialog {
    &__text--green {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 133%;
      color: #00a19c;
    }
  }
</style>
