<template>
  <section
    class="get-card"
    id="bonusnaya-programma"
  >
    <div class="container">
      <div class="get-card__body">
        <div class="get-card__content">
          <h3 class="get-card__title">{{ bonusBlock.title }}</h3>
          <p class="get-card__text--green">{{ bonusBlock.subtitle }}</p>
          <p
            class="get-card__text"
            v-html="bonusBlock.description"
          ></p>
          <div class="get-card__image get-card__image--mobile">
            <!-- <picture v-lazy-load>
              <source
                type="image/webp"
                :data-srcset="require('@/assets/img/main/consult/consult_image.webp')"
              />
              <source
                type="image/png"
                :data-srcset="require('@/assets/img/main/consult/consult_image.png')"
              />
              <img
                :data-src="require('@/assets/img/main/consult/consult_image.png')"
                alt="consult"
              />
            </picture> -->
            <picture v-lazy-load>
              <source
                type="image/webp"
                :data-srcset="require('@/assets/img/main/get-card/get-card.webp')"
              />
              <source
                media="(max-width: 576px)"
                type="image/webp"
                :data-srcset="require('@/assets/img/main/get-card/get-card_sm.webp')"
              />
              <source
                media="(max-width: 576px)"
                type="image/png"
                :data-srcset="require('@/assets/img/main/get-card/get-card_sm.png')"
              />
              <img
                :data-src="require('@/assets/img/main/get-card/get-card.png')"
                alt="get-card"
              />
            </picture>
          </div>
          <div class="get-card__button">
            <VButton @click="$parent.$emit('onShowDialog', { from: this, title: 'Получить карту бесплатно' })">
              <span class="get-card__button-text">{{ bonusBlock.button }}</span>
              <span class="get-card__button-text--mobile">{{ bonusBlock.button_m }}</span>
            </VButton>
          </div>
        </div>
        <div class="get-card__image">
          <!-- <picture v-lazy-load>
            <source
              type="image/webp"
              :data-srcset="require('@/assets/img/main/consult/consult_image.webp')"
            />
            <source
              type="image/png"
              :data-srcset="require('@/assets/img/main/consult/consult_image.png')"
            />
            <img
              :data-src="require('@/assets/img/main/consult/consult_image.png')"
              alt="consult"
            />
          </picture> -->
          <picture v-lazy-load>
            <source
              media="(max-width: 576px)"
              type="image/webp"
              :data-srcset="require('@/assets/img/main/get-card/get-card_sm.webp')"
            />
            <source
              type="image/webp"
              :data-srcset="require('@/assets/img/main/get-card/get-card.webp')"
            />
            <source
              media="(max-width: 576px)"
              type="image/png"
              :data-srcset="require('@/assets/img/main/get-card/get-card_sm.png')"
            />
            <img
              :data-src="require('@/assets/img/main/get-card/get-card_sm.png')"
              alt="get-card"
            />
          </picture>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import VButton from '../UI/VButton.vue';
  import lazyLoadDirective from '@/directives/lazy-load';

  export default {
    name: 'VGetCard',
    directives: {
      lazyLoad: lazyLoadDirective,
    },
    components: { VButton },
    computed: {
      ...mapGetters('bonus', {
        bonusBlock: 'block',
      }),
    },

    methods: {
      ...mapActions('bonus', ['GET_BONUS_BLOCK']),
    },

    async mounted() {
      await this.GET_BONUS_BLOCK();
    },
  };
</script>

<style lang="scss">
  @import '@/assets/scss/smart-grid.scss';
  @import '@/assets/scss/mixins.scss';

  $bg: rgba(17, 18, 18, 0.8);
  $bd: #2a2a2a;

  .get-card {
    background: linear-gradient(180deg, #2a2a2a 0%, rgba(17, 18, 18, 0) 23%);

    @include md-block {
      background: #111212;
    }

    &__body {
      display: flex;
      padding: 60px 0 55px;

      @include md-block {
        padding: 40px 0 50px;
      }
    }

    &__content {
      flex: 1 1 auto;
      margin-right: 10.25%;

      @include md-block {
        margin-right: 0;
      }
    }

    &__image {
      position: relative;
      align-self: center;
      flex: 0 0 40.6666667%;

      @include md-block {
        display: none;
      }

      img {
        position: relative;
        z-index: 1;
        width: 100%;
      }

      &::after {
        content: '';
        position: absolute;
        left: 23%;
        top: 15%;
        display: block;
        width: 279px;
        height: 279px;
        background: #00a19c;
        opacity: 0.4;
        filter: blur(70px);
      }

      &--mobile {
        display: none;

        @include md-block {
          display: flex;
          justify-content: center;
          margin-bottom: 10px;
        }

        @include xs-block {
          &:after {
            width: 160.08px;
            height: 159.83px;
          }
        }
      }
    }

    &__title {
      margin-bottom: 24px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      line-height: 138%;
      color: #ffffff;
      @include af(32, 20);

      @include md-block {
        margin-bottom: 16px;
      }

      @include xs-block {
        max-width: 260px;
      }
    }

    &__text--green {
      margin-bottom: 8px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 133%;
      color: #00a19c;

      @include md-block {
        display: none;
      }
    }

    &__text {
      margin-bottom: 38px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #898989;

      @include md-block {
        display: none;
      }
    }

    &__button {
      .button {
        padding: 12px 22px;
      }
      @include md-block {
        .button {
          padding: 12px 22px;
          width: 100%;
        }
      }
    }

    &__button-text {
      @include md-block {
        display: none;
      }
    }

    &__button-text--mobile {
      display: none;

      @include md-block {
        display: inline;
      }
    }
  }

  .container {
  }
</style>
