<template>
	<button class="feedback"
					type="button">
		<slot></slot>
	</button>
</template>

<script>
export default {
	methods: {

	},
}
</script>

<style lang="scss">
.feedback {
	background: inherit;
	border: none;
}
</style>
